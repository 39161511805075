import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M6225 9100 c-568 -45 -1109 -366 -1420 -844 -138 -212 -228 -438
-282 -711 -28 -141 -25 -511 5 -660 149 -740 674 -1298 1393 -1480 284 -72
609 -74 894 -5 491 119 911 429 1175 866 297 491 346 1136 126 1669 -180 435
-498 773 -921 980 -145 70 -242 105 -390 140 -178 42 -398 59 -580 45z m496
-134 c585 -122 1061 -511 1288 -1051 254 -606 156 -1296 -258 -1807 -292 -360
-709 -587 -1183 -644 -340 -41 -714 32 -1028 198 -620 331 -997 1027 -931
1718 76 798 662 1445 1438 1589 149 28 173 29 378 25 142 -3 205 -8 296 -28z"/>
<path d="M5175 4963 c-132 -18 -245 -95 -303 -208 -37 -72 -44 -224 -13 -306
41 -107 121 -184 236 -225 37 -13 78 -18 160 -18 122 1 165 12 243 66 66 45
99 83 135 158 29 58 32 74 32 155 0 77 -4 99 -29 152 -74 163 -259 253 -461
226z m202 -195 c113 -75 116 -281 5 -366 -31 -23 -46 -27 -116 -30 -69 -2 -87
1 -121 20 -62 37 -98 96 -103 172 -10 153 74 240 224 234 57 -3 79 -8 111 -30z"/>
<path d="M3310 4585 l0 -365 335 0 335 0 0 80 0 80 -232 2 -233 3 0 55 0 55
233 3 232 2 0 80 0 80 -235 0 -236 0 3 63 3 62 233 3 232 2 0 80 0 80 -335 0
-335 0 0 -365z"/>
<path d="M4080 4585 l0 -365 233 0 c251 0 308 8 372 51 44 30 65 74 65 137 0
62 -30 109 -88 139 l-44 22 29 14 c102 54 125 196 45 280 -67 71 -143 87 -407
87 l-205 0 0 -365z m434 189 c20 -8 35 -55 25 -81 -13 -35 -41 -43 -153 -43
l-106 0 0 63 c0 35 3 67 8 71 7 9 201 0 226 -10z m27 -293 c26 -32 24 -57 -8
-78 -22 -15 -49 -18 -138 -18 l-110 0 -3 49 c-5 80 -7 79 124 74 107 -3 117
-5 135 -27z"/>
<path d="M5757 4944 c-4 -4 -7 -169 -7 -366 l0 -358 100 0 100 0 -2 363 -3
362 -90 3 c-50 1 -94 0 -98 -4z"/>
<path d="M6070 4585 l0 -367 223 5 c238 5 290 14 371 61 101 59 150 155 149
295 0 102 -12 150 -53 214 -35 56 -100 100 -190 129 -55 18 -98 21 -282 26
l-218 4 0 -367z m443 178 c32 -10 56 -26 75 -51 24 -31 27 -46 30 -121 3 -77
1 -90 -20 -120 -13 -19 -37 -43 -54 -53 -34 -20 -168 -41 -230 -36 l-39 3 -3
190 c-1 104 0 195 2 202 7 17 172 7 239 -14z"/>
<path d="M7095 4698 c-71 -139 -155 -304 -187 -365 l-58 -113 110 0 109 0 26
55 25 55 202 -2 203 -3 25 -52 25 -53 113 0 c61 0 112 3 112 6 0 3 -63 128
-139 277 -77 150 -159 311 -183 359 l-43 87 -106 0 -106 1 -128 -252z m345
-199 c0 -5 -52 -9 -115 -9 -74 0 -115 4 -115 11 0 5 25 63 56 127 l56 117 59
-119 c33 -65 59 -122 59 -127z"/>
<path d="M7840 4870 l0 -79 123 -3 122 -3 3 -282 2 -283 100 0 100 0 2 283 3
282 132 3 131 3 4 75 c2 42 1 78 -1 80 -2 2 -166 4 -363 4 l-358 0 0 -80z"/>
<path d="M8840 4728 c-62 -123 -146 -287 -187 -365 l-74 -143 110 0 110 0 26
55 25 55 204 0 204 0 22 -55 22 -55 114 0 c63 0 114 2 114 5 0 6 -297 590
-341 670 l-28 50 -103 3 -104 3 -114 -223z m330 -229 c0 -5 -52 -9 -115 -9
-74 0 -115 4 -115 11 0 5 25 63 56 127 l56 117 59 -119 c33 -65 59 -122 59
-127z"/>
<path d="M6530 4000 c0 -47 -1 -50 -19 -40 -35 18 -86 12 -109 -13 -29 -31
-30 -95 -1 -131 15 -20 29 -26 58 -26 20 0 43 6 50 13 10 10 14 9 22 -5 5 -10
16 -15 24 -12 12 5 15 31 15 135 0 122 -1 129 -20 129 -17 0 -20 -7 -20 -50z
m-18 -77 c39 -35 11 -103 -43 -103 -29 0 -59 31 -59 60 0 50 64 78 102 43z"/>
<path d="M7557 4043 c-4 -3 -7 -62 -7 -130 0 -103 2 -123 15 -123 13 0 15 21
15 130 0 119 -4 142 -23 123z"/>
<path d="M6150 4020 c0 -13 7 -20 19 -20 25 0 30 -19 30 -100 -1 -104 0 -110
16 -110 13 0 15 21 15 125 l0 125 -40 0 c-33 0 -40 -3 -40 -20z"/>
<path d="M6877 4014 c-4 -4 -7 -16 -7 -26 0 -10 -7 -21 -15 -24 -8 -4 -15 -10
-15 -15 0 -5 7 -9 15 -9 12 0 15 -13 15 -56 0 -74 12 -94 55 -94 40 0 50 26
13 32 -20 3 -23 10 -26 61 -3 55 -2 57 23 57 16 0 25 6 25 15 0 9 -6 15 -12
14 -28 -3 -37 2 -40 23 -3 22 -19 33 -31 22z"/>
<path d="M4757 3973 c-3 -4 11 -48 31 -96 40 -97 37 -119 -12 -125 -36 -5 -28
-26 12 -30 24 -3 34 2 48 23 27 42 95 218 86 227 -13 13 -28 -6 -51 -69 -13
-35 -26 -63 -30 -63 -3 0 -17 30 -31 67 -23 62 -42 85 -53 66z"/>
<path d="M5184 3965 c-4 -9 -4 -47 -1 -83 7 -75 21 -92 72 -92 17 0 37 6 44
13 10 10 14 9 22 -5 5 -10 16 -15 24 -12 21 8 22 179 1 187 -22 8 -26 -2 -26
-60 0 -75 -39 -113 -83 -80 -11 8 -17 31 -19 76 -3 63 -21 92 -34 56z"/>
<path d="M5423 3973 c-9 -3 -13 -32 -13 -94 0 -82 1 -89 20 -89 18 0 20 7 20
60 0 57 2 62 30 78 30 17 40 42 17 42 -7 0 -18 -5 -25 -12 -9 -9 -15 -8 -24 4
-7 9 -19 14 -25 11z"/>
<path d="M5637 3973 c-4 -3 -7 -46 -7 -95 0 -81 2 -88 20 -88 18 0 20 7 20 58
0 73 23 102 68 86 25 -9 27 -14 32 -77 3 -37 8 -67 13 -67 4 0 12 0 17 0 14 0
12 120 -3 150 -10 20 -21 26 -54 28 -26 2 -46 -2 -56 -12 -13 -14 -15 -13 -20
4 -5 20 -18 26 -30 13z"/>
<path d="M7315 3968 c-10 -25 -1 -248 10 -251 6 -1 12 18 15 43 4 34 9 44 20
41 57 -16 90 -12 114 13 55 54 18 156 -56 156 -18 0 -40 -5 -48 -10 -10 -6
-20 -4 -32 7 -15 13 -18 14 -23 1z m127 -45 c35 -32 12 -103 -34 -103 -39 0
-58 19 -58 58 0 21 5 43 12 50 17 17 58 15 80 -5z"/>
<path d="M7657 3974 c-14 -14 -7 -139 9 -162 17 -25 80 -31 103 -9 11 10 14
10 18 0 3 -7 11 -13 18 -13 10 0 14 20 17 73 2 39 2 82 0 95 -5 34 -27 21 -30
-19 -6 -80 -13 -100 -38 -109 -46 -18 -63 1 -66 77 -3 61 -14 84 -31 67z"/>
<path d="M8006 3965 c-9 -9 -17 -11 -21 -5 -3 5 -24 10 -45 10 -30 0 -43 -6
-59 -26 -46 -59 -10 -144 62 -144 18 0 38 5 44 11 20 20 26 -10 8 -38 -16 -23
-21 -25 -66 -20 -37 5 -49 3 -49 -7 0 -18 22 -26 74 -26 66 0 84 26 86 125 4
143 0 154 -34 120z m-5 -62 c7 -19 6 -32 -6 -49 -36 -55 -119 -11 -95 50 19
52 81 51 101 -1z"/>
<path d="M4979 3944 c-35 -37 -33 -98 4 -130 65 -55 157 -16 157 66 0 57 -34
90 -93 90 -34 0 -49 -6 -68 -26z m121 -36 c13 -25 13 -31 0 -55 -30 -54 -110
-36 -110 24 0 64 79 85 110 31z"/>
<path d="M5884 3947 c-21 -19 -27 -32 -27 -67 0 -58 35 -90 97 -90 56 0 91 34
91 90 0 56 -35 90 -91 90 -30 0 -51 -7 -70 -23z m116 -39 c13 -25 13 -31 0
-55 -21 -38 -71 -44 -94 -11 -9 12 -16 29 -16 38 0 9 7 26 16 38 23 33 73 27
94 -10z"/>
<path d="M6655 3957 c-25 -19 -4 -30 30 -17 33 12 65 -2 65 -30 0 -17 -7 -20
-44 -20 -48 0 -76 -19 -76 -50 0 -36 27 -50 95 -48 l65 2 0 72 c0 56 -4 74
-18 87 -22 21 -91 22 -117 4z m100 -97 c11 -17 -31 -48 -58 -43 -32 6 -42 29
-19 42 22 13 69 14 77 1z"/>
<path d="M7017 3959 c-28 -17 -11 -27 37 -22 37 5 46 2 55 -14 14 -27 6 -33
-45 -33 -46 0 -74 -19 -74 -50 0 -36 27 -50 95 -48 l65 2 0 72 c0 53 -4 77
-16 88 -18 18 -89 21 -117 5z m97 -97 c10 -17 -20 -44 -48 -44 -35 0 -53 16
-38 34 13 16 78 23 86 10z"/>
<path d="M6080 3810 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
